import React from "react"
import styled from "styled-components"
import { graphql } from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { MARKS, BLOCKS } from '@contentful/rich-text-types'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import NewsletterCTA from '../components/NewsletterCTA'

import FAQIcon from '../images/icon-faq.svg'

const FAQsContainer = styled.section`
  margin: 0 auto 120px auto;
  max-width: 850px;

  h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  .accordion {
    margin-bottom: 80px;
  }

  .accordion__item {
    border: 1px solid var(--white);
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
    position: relative;
    z-index: 1;

    @media(min-width: 640px) {
      padding: 15px 15px 15px 45px;
    }

    &:hover {
      border: 1px solid transparent;
      background:
        linear-gradient(var(--black), var(--black)) padding-box,
        linear-gradient(to right, var(--blue), var(--pink)) border-box;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .accordion__button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: 23px;
        right: 15px;
        width: 18px;
        height: 12px;
        background-size: 18px 12px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(${FAQIcon});
        z-index: -1;
      }

      h3 {
        margin-bottom: 0;
        font-size: 24px;
        font-weight: var(--font-bold);
        font-family: var(--body-font);
        color: var(--pink);
        cursor: pointer;
      }
    }

    .accordion__button[aria-expanded='true']::after {
      transform: rotate(180deg);
    }

    .accordion__panel {
      margin-top: 25px;

      p {
        font-size: 18px;
        line-height: 1.1;
        color: var(--gray);
        
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .accordion__panel[hidden]::after {
      transform: rotate(180deg);
    }
  }
`

const FAQsPage = (props) => {
  
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    }
  };

  return (
    <main>
      {props.data.pageTitleData.edges.map(({ node }) => (
        <PageTitle key={node.id} title={node.faqsPageTitle} paragraph={node.faqsPageTitleParagraph.faqsPageTitleParagraph} />
      ))}
      <FAQsContainer>
        <h2>Private Fund Services</h2>
        <Accordion>
        {props.data.PrivateFund.edges.map(({ node }, i) => (
          // <AccordionItem key={node.id} className={`accordion__item ${faqActive ? "active" : ""}`} onClick={toggleActive} >
          <AccordionItem key={node.id}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h3>{node.faqTitle}</h3>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {node.faqContent && renderRichText(node.faqContent, options)}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
        </Accordion>

        <h2>Registered Fund Legal Services</h2>
        <Accordion>
        {props.data.RegisteredFund.edges.map(({ node }) => (
          <AccordionItem key={node.id}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h3>{node.faqTitle}</h3>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {node.faqContent && renderRichText(node.faqContent, options)}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
        </Accordion>

        <h2>RIA Legal Services</h2>
        <Accordion>
        {props.data.RIAServices.edges.map(({ node }) => (
          <AccordionItem key={node.id}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h3>{node.faqTitle}</h3>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {node.faqContent && renderRichText(node.faqContent, options)}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
        </Accordion>

        <h2>Startup Services</h2>
        <Accordion>
        {props.data.StartupServices.edges.map(({ node }) => (
          <AccordionItem key={node.id}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h3>{node.faqTitle}</h3>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {node.faqContent && renderRichText(node.faqContent, options)}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
        </Accordion>

        <h2>SEC Compliance Services</h2>
        <Accordion>
        {props.data.SECServices.edges.map(({ node }) => (
          <AccordionItem key={node.id}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h3>{node.faqTitle}</h3>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {node.faqContent && renderRichText(node.faqContent, options)}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
        </Accordion>
      </FAQsContainer>
      <NewsletterCTA />
    </main>
  )
}

export default FAQsPage

export const Head = (seo) => {
  const seoData = seo.data.pageTitleData
  return (
    <>
      {seoData.edges.map(({ node }) => (
      <Seo key={node.id} title={node.seoTitle} description={node.seoDescription.seoDescription} image={node.seoImage.url} />
      ))}
    </>
  )
}

export const PrivateFundContent = graphql`
  {
  pageTitleData: allContentfulFaQsPage {
    edges {
      node {
        id
        faqsPageTitle
        faqsPageTitleParagraph {
          faqsPageTitleParagraph
        }
        seoTitle
        seoDescription {
          seoDescription
        }
        seoImage {
          url
        }
      }
    }
  }
  PrivateFund: allContentfulFaqs(filter: {faqCategories: {eq: "Private Fund Services"}}) {
    edges {
      node {
        faqContent {
          raw
        }
        faqTitle
        faqCategories
        id
      }
    }
  }
  RegisteredFund: allContentfulFaqs(filter: {faqCategories: {eq: "Registered Fund Legal Services"}}) {
    edges {
      node {
        faqContent {
          raw
        }
        faqTitle
        faqCategories
        id
      }
    }
  }
  RIAServices: allContentfulFaqs(filter: {faqCategories: {eq: "RIA Legal Services"}}) {
    edges {
      node {
        faqContent {
          raw
        }
        faqTitle
        faqCategories
        id
      }
    }
  }
  StartupServices: allContentfulFaqs(filter: {faqCategories: {eq: "Startup Services"}}) {
    edges {
      node {
        faqContent {
          raw
        }
        faqTitle
        faqCategories
        id
      }
    }
  }
  SECServices: allContentfulFaqs(filter: {faqCategories: {eq: "SEC Compliance Services"}}) {
    edges {
      node {
        faqContent {
          raw
        }
        faqTitle
        faqCategories
        id
      }
    }
  }
}`;